import React, { Component } from 'react';
import GestGemsysService  from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import moment from 'moment';
import './styles.css';
import { Panel } from 'primereact/panel';

export class FormStatut extends Component {
    constructor() {
        super();
        this.state = {
            statutAccademiques: [
                { label: 'Formation en cours', value: 'Formation en cours' },
                { label: 'Transféré', value: 'Transféré' },
                { label: 'Abandon', value: 'Abandon' },
                { label: 'Décédé', value: 'Décédé' },
                { label: 'Diplômé', value: 'Diplômé' }
            ],
            statutFinanciers: [
                { label: 'Boursier', value: 'Boursier' },
                { label: 'Formation payante', value: 'Formation payante' },
                { label: 'Exampté', value: 'Exampté' }
            ],
            numStatut: '',
            statutAccademique: '',
            statutFinancier: '',
            codeStatut: '',
            iduser: '',
            idEtabl: 1,
            liste_satuts: [],
            selectedStatut: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_satut = this.edit_satut.bind(this);
        this.conf_delete_statut = this.conf_delete_statut.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_satuts();
    }

    get_liste_satuts() {
        this.setState({showindicator: true});
        this.gestGemsysService.getStatut().then(data => this.setState({ liste_satuts: data,showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_satut();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_satut(data) {
        if (data != null && data != undefined) {
            this.setState({ numStatut: data.numStatut });
            this.setState({ statutAccademique: data.statutAccademique });
            this.setState({ statutFinancier: data.statutFinancier });
            this.setState({ codeStatut: data.codeStatut });
            this.setState({ iduser: data.iduser });
            this.setState({ idEtabl: data.idEtabl });
            this.setState({ modif: true });
            this.setState({ selectedStatut: data });
            window.scrollTo(0, 0);
        }
    }

    annule_satut() {
        this.setState({ numStatut: '' });
        this.setState({ statutAccademique: '' });
        this.setState({ statutFinancier: '' });
        this.setState({ codeStatut: '' });
        this.setState({ iduser: '1' });
        this.setState({ idEtabl: 1 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_satut() {
        if (this.state.codeStatut == "" || this.state.statutAccademique == "" || this.state.statutFinancier == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let statut = {
                    numStatut: '0',
                    statutAccademique: this.state.statutAccademique,
                    statutFinancier: this.state.statutFinancier,
                    codeStatut: this.state.codeStatut
                };
                this.gestGemsysService.AddStatut(statut).then(data => this.resultat(data.code, data.contenu));
            } else {
                let statut = this.state.selectedStatut;
                statut.statutAccademique = this.state.statutAccademique;
                statut.statutFinancier = this.state.statutFinancier;
                statut.codeStatut = this.state.codeStatut;

                this.gestGemsysService.UpdateStatut(statut).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_satut(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_statut(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -22 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_statut() {
        if (this.state.selectedStatut == null || this.state.selectedStatut == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gestGemsysService.DeleteStatut(this.state.selectedStatut).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_statut(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedStatut: data });
            this.setState({ msg: data.numStatut });
            this.setState({ visible: true });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_statut();
                }} label="Oui" loading={this.state.showindicator}  />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="p-fluid p-formgrid grid">
                                <div className="col-12 md:col-4">
                                    <label htmlFor="codeStatut">Code statut *</label>
                                    <InputText id="codeStatut" value={this.state.codeStatut} onChange={(e) => this.setState({ codeStatut: e.target.value })} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label htmlFor="statutAccademique">Statut academique *</label>
                                    <Dropdown options={this.state.statutAccademiques} value={this.state.statutAccademique} onChange={(e) => this.setState({ statutAccademique: e.value })} autoWidth={false} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label htmlFor="statutFinancier">Statut Financier *</label>
                                    <Dropdown options={this.state.statutFinanciers} value={this.state.statutFinancier} onChange={(e) => this.setState({ statutFinancier: e.value })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_satut()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator}  icon="pi pi-check" onClick={(e) => this.enreg_satut()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.liste_satuts} selectionMode="single" style={{ minHeight: '600px' }}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedStatut: e.data })}
                                onRowSelect={e => this.setState({ selectedStatut: e.data })}
                                selection={this.state.selectedStatut} dataKey="numStatut" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={100}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '15%' }} />
                                <Column field="statutAccademique" header="Statut académique" style={{ width: '35%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="statutFinancier" header="Statut financier" style={{ width: '35%' }} filter={true} filterMatchMode='contains' />
                                <Column field="codeStatut" header="Code" style={{ width: '15%' }}  filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}